<template>
  
	<!-- productos -->
	<div class="layout--main" id="cotizacion">
		<div class="container mt-12 pb-12">

			<div class="row mb-8 mt-16">
        <button
					@click="regresar()"
					type="button" 
					class="btn regresar w-48 flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="14"
            viewBox="0 0 30 22"
            fill="none"
          >
            <path
              d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z"
              fill="#FF7150"
            />
          </svg>

          <span class="ml-4 regresar"> Regresar </span>
        </button>
			</div>

			<!-- ordernar -->
			<div class="row flex flex-col mt-4 calculadora-inmob-ordenar">
				Ordenar por
        
				<div class="flex flex-row flex-wrap mt-4">
					
					<div
						v-for="filtro in filtros" 
						:key="filtro.value" 
						class="col-sm-2 calculadora-inmob-ordenar-item">
						<div class="form-check">
							<input 
								class="form-check-input" 
								type="radio" 
								v-model="filtroModel"
								name="inlineRadioOptions" 
								:id="'inlineRadio' + filtro.value" 
								@change="filtrar(filtro.value)"
								:value="filtro.value">
							<label for="flexRadioDefault1">{{ filtro.label }}</label>
						</div>
					</div>
					
				</div>
			</div>

			<!-- titulos -->
			<div class="row mt-8 p-4 bg-calculadora">
				<div class="row flex flex-row">
					<div class="col-sm-3 font-semibold tabla-dato-prod">Banco</div>
					<div class="col-sm-3 font-semibold tabla-dato-prod">Producto</div>
					<div class="col-sm-3 font-semibold tabla-dato-prod">Mensualidad
						<svg xmlns="http://www.w3.org/2000/svg" height="20"  width="20" viewBox="0 -960 960 960"  fill="#000"><path d="m 477,-246 158,-157 -42,-42 -85,84 v -359 h -60 v 359 l -85,-84 -42,42 z m 3,166 c -54.66667,0 -106.33333,-10.5 -155,-31.5 -48.66667,-21 -91.16667,-49.66667 -127.5,-86 -36.33333,-36.33333 -65,-78.83333 -86,-127.5 -21,-48.66667 -31.5,-100.33333 -31.5,-155 0,-55.33333 10.5,-107.33333 31.5,-156 21,-48.66667 49.66667,-91 86,-127 36.33333,-36 78.83333,-64.5 127.5,-85.5 48.66667,-21 100.33333,-31.5 155,-31.5 55.33333,0 107.33333,10.5 156,31.5 48.66667,21 91,49.5 127,85.5 36,36 64.5,78.33333 85.5,127 21,48.66667 31.5,100.66667 31.5,156 0,54.66667 -10.5,106.33333 -31.5,155 -21,48.66667 -49.5,91.16667 -85.5,127.5 -36,36.33333 -78.33333,65 -127,86 -48.66667,21 -100.66667,31.5 -156,31.5 z m 0,-60 c 94.66667,0 175,-33.16667 241,-99.5 66,-66.33333 99,-146.5 99,-240.5 0,-94.66667 -33,-175 -99,-241 -66,-66 -146.33333,-99 -241,-99 -94,0 -174.16667,33 -240.5,99 -66.33333,66 -99.5,146.33333 -99.5,241 0,94 33.16667,174.16667 99.5,240.5 66.33333,66.33333 146.5,99.5 240.5,99.5 z"/></svg>
					</div>
					<div class="col-sm-3 font-semibold tabla-dato-prod"> Acciones</div>
				</div>
			</div>

			<!-- productos -->
			<div 
				v-for="(item, index) in items" :key="index"
				class="row">
				<CalculadoraProductoItem :item="item">

					<template 
						slot="boton-accion-visualizar" 
						v-if="item.BancoId != 21">

						<button 
							v-if="item.BancoId != 33"
							@click="solicitar(item)"
							type="button" 
							class="btn btn-visualizar" 
							data-bs-toggle="modal" 
							data-bs-target="#staticBackdrop">Visualizar
						</button>

					</template>

				</CalculadoraProductoItem>

			</div>

		</div>

		<!-- modal pdf producto -->
		<div 
			class="modal fade modal-calc-inmob-simula" 
			id="staticBackdrop" 
			data-bs-backdrop="static" 
			data-bs-keyboard="false" 
			tabindex="-1" 
			aria-labelledby="staticBackdropLabel" 
			aria-hidden="true">
			
			<!-- modal tiene que tener scroll -->
			<div class="modal-dialog  modal-dialog-scrollable modal-xl">
				<div class="modal-content">
					
					<!-- header -->
					<div class="modal-header">
						<h5 class="modal-title" id="staticBackdropLabel"></h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
      
					<!-- body -->
					<div class="modal-body">
						<div class="container p-4 bg-modal-producto flex flex-row justify-center marco-contendor row">
							<div class="col-sm-12 pt-8 pb-0 px-4 lg:px-32 bg-white">
								
								<div class="row w-full flex flex-row justify-left items-center">
									<div class="col-md-8">
										<img src="https://cotizador.socasesores.com/uploads/logo_soc.png" alt="" width="220" height="50" class="modal-calc-inmob-simula-logo">
									</div>
									<div class="col-md-4 mt-4 lg:mt-0">
										<b class="leading-tight texto-verde-imp" style="font-size: 20px;">Comparador SOC </b>
									</div>
								</div>								

								<div class="row mt-16">
									<span class="font-bold texto-verde-imp" style="font-size: 20px;">Hola {{ nombreSolicitante }}</span>
								</div>
								
								<div class="row mt-8 text-justify texto-verde-imp">
									<p>Tu asesor inmobiliario <b>{{ activeUserInfo.Nombre }}</b> realizo una simulaci&oacute;n con las siguientes condiciones:</p>
								</div>
								
								<div class="row mt-8">
									<span class="font-bold texto-verde-imp">&bull; Valor de la vivienda: ${{ caso.valorInmueble }}</span>
									<span class="font-bold texto-verde-imp">&bull; Monto de cr&eacute;dito: ${{ caso.montoSolicitado }}</span>
									<span class="font-bold texto-verde-imp">&bull; Enganche: ${{ enganche }}</span>
								</div>

            					<div class="row mt-8 text-justify texto-verde-imp">
									<p>Trabajamos de la mano de un asesor hipotecario <b>{{ brokerSupervisor }}</b> que tiene alianza con las principales instituciones financieras de México, esto nos permite ayudarte a encontrar la mejor opci&oacute;n crediticia de acuerdo con tu perfil y necesidades.</p>
								</div>
								
								<!-- logos bancos -->
								<div class="row mt-8">
									<div class="col-md-12 flex flex-row justify-between aling-bancos ">

										<img class="w-full mr-6" src="https://cotizador.socasesores.com/uploads/bannerFinancierasInmobiliario.png" alt="">																				
									
									</div>
								</div>
								
								
								<div class="row">
									<p class="texto-verde-imp"><br>Conoce un estimado de las condiciones de tu cr&eacute;dito</p>
								</div>
								
								<div class="row">
									<span class="font-bold texto-verde-imp mt-1">&bull; Banco seleccionado:
										<span class="font-bold texto-verde-imp ml-2">{{ bancoSeleccionado }}</span>
									</span>
									
									<span class="font-bold texto-verde-imp mt-1">&bull; Mensualidad promedio:
										<span class="font-bold texto-verde-imp ml-2">${{ mensualidadPromedio }}</span>
									</span>
									
									<span class="font-bold texto-verde-imp mt-1">&bull; Tasa:
										<span class="font-bold texto-verde-imp ml-2">${{ tasa }}</span>
									</span>
									
									<span class="font-bold texto-verde-imp mt-1">&bull; Gastos notariales promedio:
										<span class="font-bold texto-verde-imp ml-2">${{ gastosNotariales }}</span>
									</span>
									
									<span class="font-bold texto-verde-imp mt-1">&bull; Ingreso mínimo:
										<span class="font-bold texto-verde-imp ml-2">${{ ingresoMinimo }}</span>
									</span>

									<span class="font-bold texto-verde-imp mt-1">&bull; CAT promedio:
										<span class="font-bold texto-verde-imp ml-2">${{ catPromedio }}</span>
									</span>
								</div>
								
								<div class="row mt-8 text-justify texto-verde-imp">
									<p> ¿Te gustaría recibir asesoría de un experto? Te acompañamos desde la elección de tu crédito hasta la firma de tus escrituras. <b>¡Nuestro servicio no tiene costo!</b></p>									
								</div>

								<div class="texto-verde-imp" style="text-align: center;">
									<b>Juntos, lo hacemos real</b>
								</div>
								
								<div class="row mt-8 text-justify mb-32 texto-verde-imp">
									<p><b>* Importante:</b> Las condiciones presentadas son <b>informativas y referenciales</b>, por lo que, éstas podrían
                					cambiar de acuerdo con el análisis de tu perfil y los parámetros del banco.</p>
								</div>																				
							</div>
							<!-- Footer -->
							<div class="pt-0 pb-0 pr-0 pl-0 bg-white">									
								<div class="row">
									<div class="col-md-12 flex flex-row">
										<img src="https://cotizador.socasesores.com/uploads/cintillo_Logo_Soc.png" alt="" width="116.5%" height="40px" class="w-full h-auto">										
									</div>
								</div>
							</div>
							
						</div>											
					</div>
					
					
					<div class="modal-footer">
						<button type="button" class="btn btn-regresar-modal" data-bs-dismiss="modal">Regresar</button>
						<button 
							@click="actualizaOrigen()"
							type="button" 
							class="btn btn-success">Guardar y descargar</button>
					</div>
					
				</div>
			</div>

		</div>

	</div>



</template>


<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "CalculadoraProducto",
  components: {
		CalculadoraProductoItem: defineAsyncComponent(() => import("@/views/CasoInmobiliaria/nuevasVistas/Calculadora/CalculadoraProductoItem.vue")),
	},
  props: {},
  data() {
    return {
			items: [],
			caso: {
				valorInmueble: '',
				montoSolicitado: '',
				email: '',
				gastosNotarial: 3,
				bancos: [],
				esquemas: [],
				plazos: [],
				programa: '',
				saldoInsoluto: '',
				pagoMensualActual: '',
			},

			destino: '',
			filtroName: '',
			filtroId: '',

			filtroModel: 1,

			// modal preview
			nombreSolicitante: '',
			enganche: 0,
			bancoSeleccionado: '',
			mensualidadPromedio: 0,
			tasa: 0,
			gastosNotariales: 0,
			ingresoMinimo: 0,
			catPromedio: 0,
			s: 0,
			o: 0,
			linaje: '',			
			brokerSupervisor: '',

			// kredi
			ValorInmuebleKredi: '',
			EngancheKredi: '',
			PorcentajeEngancheKredi: '',
			PlazosKredi: '',
			FinanciamientoKredi: '',
			ComisionAperturaKredi: '',
			EstadoKredi: '',
			GastosNotarialesKredi: '',

			// catalogos
			filtros: [
				{ label: 'Mensualidad',  value: '1' },
				{ label: 'Cat',  value: '2' },
				{ label: 'Tasa',  value: '3' },
				{ label: 'Pago total',  value: '4' },
				{ label: 'Con promociones',  value: '5' },
			],
		};
  },
  mounted() {
    // console.log("CreandoCalculadoraProducto");

		this.id = this.$route.params.id; // caso
    this.getApikey();
		this.dameCaso();
		this.cotiza();
		this.activaPanel();		
		this.dameSupervisor()

		// console.log( this.id );
		// console.log( 'DEBUG CASO', this.caso );
		// console.log( 'DEBUG ITEMS', this.items );
  },
  watch: {},
  computed: {
		activeUserInfo() {			
			return this.$store.state.AppActiveUser;
		},
	},
	methods: {
		dameSupervisor(){			
			let self = this	
			let objRequestDameSupervisor = {
				strApiKey: this.key,
				strMetodo: 'dameSupervisorHipotecario',
				objBroker: {
					Linaje: this.linaje
				}
			}
			this.$axios.post( '/', objRequestDameSupervisor, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				}
			})
			.then(response => {
						if(response.data.intEstatus == 0) {
							this.brokerSupervisor = response.data.objContenido								
						} else {
							this.$vs.notify({
								title:'Ocurrio un error en DameSupervisor',
								text:response.data.strError,
								color:'danger',
								position:'top-right'
							});
				}
					})
					.catch(function (error) {
						self.$vs.notify({
							title:'Ocurrio un error de sistema',
							text:error,
							color:'danger',
							position:'top-right'
				})
			})
		},
			activaPanel(){
				this.$emit('actualiza_panel', 'HomeInmo', 'Calculadora')
			},
		getApikey() {
			if (!JSON.parse(localStorage.getItem("userInfo")).ApiKey) {
				this.$router.push("/login");
			} else {
				this.key = JSON.parse(localStorage.getItem("userInfo")).ApiKey;
				this.linaje = JSON.parse(localStorage.getItem("userInfo")).Linaje;
			}

    },
		dameCaso(flag) {
      let self = this
      let objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id
        }
      }
			this.$axios.post( '/', objRequestDameCaso, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				}
			})
      .then(response => {
				if(response.data.intEstatus == 0) {
					let cotizacion = response.data.objContenido;

					// TODO: Revisar si lo siguiente es util o son procesos sin uso
					
					this.caso.plazos = [];
					this.caso.esquemas = [];
					this.caso.bancos = [];
					this.caso.bancos = cotizacion.ComparaBancos;
					// this.caso.estatus = cotizacion.Estatus;
					// this.caso.solicitante = cotizacion.Solicitante;
					this.destino = cotizacion.Destino;
					// this.caso.ejecutivo = cotizacion.Broker;
					this.caso.email = cotizacion.Solicitante;
					// this.caso.inmuebleNuevo = cotizacion.Nuevo;
					// this.caso.plaza = cotizacion.Plaza;
					// this.caso.estado = cotizacion.EstadoInmueble;
					// this.caso.tasa = cotizacion.PerfilCliente;
					// this.caso.gastosNotarial = cotizacion.GastosNotariales;
					
					if (this.destino == 19) { // los ids de destino en esta seccion solo son 1,2,5,19
						this.caso.saldoInsoluto = cotizacion.SaldoInsoluto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						this.caso.pagoMensualActual = cotizacion.PagoMensualActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
					
					this.caso.valorInmueble = cotizacion.ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					
					for (var i = 0; i < cotizacion.Plazos.length; i++) {
						this.caso.plazos.push(cotizacion.Plazos[i]);
					}
					
					for (var i = 0; i < cotizacion.Esquemas.length; i++) {
						if (cotizacion.Esquemas[i] == 2) {
							this.caso.esquemas.push({label: 'Pago Fijo', value: '2'});
						} else if (cotizacion.Esquemas[i] == 1) {
							this.caso.esquemas.push({label: 'Pago creciente', value: '1'});
						}
					}
					
					// this.caso.plazoRestante=cotizacion.PlazoRestante;
					this.caso.montoSolicitado = cotizacion.MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// this.caso.favoritos=cotizacion.Favoritos;
					
					// if (flag) {
					// 	for (var i = 0; i < this.caso.favoritos.length; i++) {
					// 		this.quitarFavorito(this.caso.favoritos[i]);
					// 	}
					// }
					
					if (cotizacion.Programas.length) {
						this.caso.programa = cotizacion.Programas[0];
					}
					
					// this.caso.solicitudes = cotizacion.Solicitudes;
					
					// for (var i = 0; i < this.caso.solicitudes.length; i++) {
					// 	this.caso.solicitudes[i].MensualidadPromedio = this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// 	this.caso.solicitudes[i].Cat = this.caso.solicitudes[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// }
					
					// if (this.caso.estado) {
					// 	this.getPlazas();
					// }
					
					// if(cotizacion.CreditoInfonavit) {
					// 	this.caso.creditoInf = cotizacion.CreditoInfonavit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// }
					
					// if(cotizacion.SubCuentaVivienda) {
					// 	this.caso.ssv = cotizacion.SubCuentaVivienda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// }
					
					this.dameSolicitante();

				} else {
					this.$vs.notify({
						title:'Ocurrio un error en DameCaso',
						text:response.data.strError,
						color:'danger',
						position:'top-right'
					});
        }
			})
			.catch(function (error) {
				self.$vs.notify({
					title:'Ocurrio un error de sistema',
					text:error,
					color:'danger',
					position:'top-right'
        })
      })
		},
		dameSolicitante() {
			let self = this
			let objRequestDameSolicitante = {
				strApiKey: this.key,
				strMetodo: 'DameSolicitante',
				objSolicitante: {
					EMail: this.caso.email,
				}
			}

			this.$axios.post( '/', objRequestDameSolicitante, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
				}
			})
			.then(response => {
				if (response.data.intEstatus == 0) {
					// this.ingreso = response.data.objContenido.Ingresos[0];
					
					let solicitante = response.data.objContenido;
					this.nombreSolicitante = solicitante.Nombres;

				} else {
					this.$vs.notify({
						title: 'Ocurrio un error en DameSolicitante',
						text: response.data.strError,
						color: 'danger',
						position: 'top-right',
					})
				}
			})
			.catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
				})
			});
		},
		async getListProductsMensualidad ({ listProducts }) {

			try {

			const objRequestListProducts = {
				strApiKey: this.key,
				strMetodo: 'ListProductsMensualidad',
				objList: {
				listProducts
				}
			}

			const resp = await this.$axios.post('/', objRequestListProducts, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
			const { intEstatus, data } = resp
			const { objContenido } = data

			if ( !objContenido ) {
				this.$vs.notify({
				title: 'Ocurrio un error en ListProductsMensualidad',
				text: data.strError,
				color: 'danger',
				position: 'top-right',
				})

				return []
			}

			return objContenido

			} catch (error) {
			this.$vs.notify({
				title: 'Ocurrio un error de sistema 4',
				text: error,
				color: 'danger',
				position: 'top-right',
			})
			}

		},
		async cotiza() {
			try {
							
			// this.$vs.loading({ // TODO: Revisar
			// 	container: '#cotizacion',
			// 	scale: 0.6
			// });
			let self = this;
			this.items = [];
			
			this.$vs.loading({
				container: '#cotizacion',
				scale: 0.6,
			});
			
			let objRequestCotizaCaso = {
				strApiKey: this.key,
				strMetodo: 'Cotiza',
				objCaso: {
					Id: this.id,
					GastoNotarial: this.caso.gastosNotarial
				}
			}
			
			const resp = await  this.$axios.post( '/', objRequestCotizaCaso, {headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})
			const { intEstatus, data } = resp

			if ( intEstatus ) {
              this.$vs.notify({
                title: 'Ocurrio un error en Cotiza',
                text: data.strError,
                color: 'danger',
                position: 'top-right',
              })
            }   
											
			// Mejora ion
			let bancos = [];
			let esquemas = [];
			
			for (var i = 0; i < this.caso.bancos.length; i++) {
				if (this.caso.bancos[i].value) {
					bancos.push(parseInt(this.caso.bancos[i].value));
				} else {
					bancos.push(parseInt(this.caso.bancos[i]));
				}
			}
			
			for (var i = 0; i < this.caso.esquemas.length; i++) {
				if(this.caso.esquemas[i].value) {
					esquemas.push(parseInt(this.caso.esquemas[i].value));
				} else {
					esquemas.push(parseInt(this.caso.esquemas[i]))
				}
			}

			// Consultar segunda mensualidad para todos los productos
			const listProducts = data.objContenido ? data.objContenido : []
			const listItems = listProducts.length > 0 ? await this.getListProductsMensualidad({ listProducts }) : listProducts
			this.itemsPrueba = listItems
					
					for (var j=0; j<this.caso.plazos.length;j++) {
						
						if ((this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==1 || this.destino==3) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10 || this.caso.plazos[j]==11 || this.caso.plazos[j]==12 || this.caso.plazos[j]==13 || this.caso.plazos[j]==14 || this.caso.plazos[j]==15 || this.caso.plazos[j]==16 || this.caso.plazos[j]==17 || this.caso.plazos[j]==18 || this.caso.plazos[j]==19 || this.caso.plazos[j]==20)) || (this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==5 || this.destino==37) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10))) {
							
							// console.log(this.caso.plazos);

							// //alert(this.caso.plazos[j]+'-'+j)
							this.pruebaIon(this.caso.plazos[j]);
							
							this.$vs.loading({
								container: '#cotizacion',
								scale: 0.6
							});
							
							//this.itemsPrueba=response.data.objContenido;
							
							var j = 0;
							
							for (var i=0; i < this.itemsPrueba.length; i++) {
								if(!this.itemsPrueba[i].Producto.includes('7X5')) {
									this.items[j] = this.itemsPrueba[i];
									j++;
								}
								//alert(this.itemsPrueba[i].Producto)
							}

							// console.log(this.items)
							this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));
							this.filtroName = 'Mensualidad';
							this.filtroId = 1;
							
							this.$vs.notify({
								title:`Cotizado y guardado exitosamente`,
								position:'top-right',
							});
							
						} else {
							
							///////////////////////////////////
							//this.itemsPrueba = response.data.objContenido;
							var j=0;
							
							for(var i=0; i < this.itemsPrueba.length; i++) {
								if(!this.itemsPrueba[i].Producto.includes('7X5')) {
									this.items[j] = this.itemsPrueba[i];
									j++;
								}
								// alert(this.itemsPrueba[i].Producto)
							}
							
							// console.log(this.items)
							this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));
							this.filtroName = 'Mensualidad';
							this.filtroId = 1;
							
							this.$vs.notify({
								title:`Cotizado y guardado exitosamente`,
								position:'top-right',
							});
							this.$vs.loading.close('#cotizacion > .con-vs-loading');
							///////////////////////////////////
            }
					} ////////// for
					
					for(var j = 0; j < this.caso.plazos.length; j++) {
						/// inicia apartado de kredi
						if (esquemas.includes(2) && bancos.includes(33) && (this.destino == 1 ) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)) {
							
							this.ValorInmuebleKredi = parseFloat(this.caso.valorInmueble.replace(/,/g,""));
							this.EngancheKredi = parseFloat(this.caso.valorInmueble.replace(/,/g,"")) - parseFloat(this.caso.montoSolicitado.replace(/,/g,""));
							this.PorcentajeEngancheKredi = parseFloat(this.caso.montoSolicitado.replace(/,/g,"")) / parseFloat(this.caso.valorInmueble.replace(/,/g,"")) * 100;
							this.PlazosKredi = this.caso.plazos[j];
							this.FinanciamientoKredi = 'No';
							this.ComisionAperturaKredi = '0.02';
							this.EstadoKredi = 20;
							this.GastosNotarialesKredi = '0.05';

							this.RegistraCasoKredi(this.destino,this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi);
							
							this.$vs.loading({
								container: '#cotizacion',
								scale: 0.6
							});
							
							//this.itemsPrueba=response.data.objContenido;
							var j = 0;
							
							for(var i=0; i < this.itemsPrueba.length; i++) {
								if(!this.itemsPrueba[i].Producto.includes('7X5')) {
									this.items[j] = this.itemsPrueba[i];
									j++;
								}
							}
							
							this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));
							this.filtroName= 'Mensualidad';
							this.filtroId = 1;
            }
          }
					//// termina apartado de kredi
					
					//this.itemsPrueba = response.data.objContenido;
					var j = 0;
					
					for(var i=0; i < this.itemsPrueba.length; i++) {
						if(!this.itemsPrueba[i].Producto.includes('7X5')) {
							this.items[j]=this.itemsPrueba[i];
							j++;
						}
          	// alert(this.itemsPrueba[i].Producto)
					}
					
					this.items.sort((a,b) => (a.MensualidadInicialPromedio > b.MensualidadInicialPromedio) ? 1 : ((b.MensualidadInicialPromedio > a.MensualidadInicialPromedio) ? -1 : 0));
					this.filtroName = 'Mensualidad';
					this.filtroId = 1;
					
					this.$vs.notify({
						title: `Cotizado y guardado exitosamente`,
						position: 'top-right',
					});
					
					this.$vs.loading.close('#cotizacion > .con-vs-loading');
					
      
				//this.$vs.loading.close('#cotizacion > .con-vs-loading')
			} catch (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color:'danger',
					position:'top-right'
				})
			}			
		},
		RegistraCasoKredi(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi) {
			let self = this;
			let producto = '';
			let respuestaKredi = '';
			
			if (destinoId == 1) {
				producto = 1;
			} else if(destinoId == 19) {
				producto = 2;
			}
			
			var product_application = {
				product_id: producto,
				answers: {
					"fecha": "26/05/2023",
					"valor_del_inmueble": ValorInmuebleKredi,
					"enganche": EngancheKredi,
					"porcentaje_de_enganche": PorcentajeEngancheKredi,
					"plazo_en_anos": PlazosKredi,
					"financiamiento_de_comision_por_apertura": FinanciamientoKredi,
					"porcentaje_comision_por_apertura": ComisionAperturaKredi,
				
					"estado_en_donde_se_encuentra_la_propiedad": EstadoKredi,
					"porcentaje_gastos_notariales":"null"

				},
				"async": false,
				"customer_id": "",
				"external_id": "",
				"dry_run": true
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredi.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {
					respuestaKredi = JSON.parse(response.data.objRespuesta);
					let itemsKredi = {};
					let items = [];
					let cantidadCotiza = items.length
					itemsKredi.Monto = parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado);
					itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat);
					itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment);
					itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes);
					itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required);
					
					itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura); 
					itemsKredi.Avaluo = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo);
					itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura);
					itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados);

					itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo;
					itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses;
					itemsKredi.PagoTotal = 0;
					itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment);
					itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment);
					itemsKredi.BancoId = 33;
					itemsKredi.Banco = 'KREDI';
					
					if(respuestaKredi.data.attributes.product_id==1) {
						itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA';
						itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch);
					}else if(respuestaKredi.data.attributes.product_id==2) {
						itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA';
						itemsKredi.Enganche = 0;
						itemsKredi.MejoraMensualidad = 0;
						itemsKredi.AhorroTotal = 0;
					}
					
					itemsKredi.Esquema = 2;
					itemsKredi.Logo = 'kredi.png';
					//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
					itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table;
					itemsKredi.IdIon = respuestaKredi.data.id;
					this.items.push(itemsKredi);
					this.$vs.loading.close('#cotizacion > .con-vs-loading');
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		pruebaIon(plazo) {
			/* product i.	Adquisición de vivienda = REAL_STATE
			ii.	Liquidación = LIQUIDITY
			iii.	Oficinas y locales = OFFICES_AND_STORES
			iv.	Adquisición de terreno = LAND
			*/
			let self = this;
			let destino = '';
			let GastosInicialCalculado = '';
			let NombreProducto = '';
			let respuestaData = '';
			let calculoAforo = '';
			
			calculoAforo = (this.caso.montoSolicitado.replace(/,/g,"")*100)/this.caso.valorInmueble.replace(/,/g,"");
			
			switch(this.destino) {
				case 1:
					destino = 'REAL_ESTATE';
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g,"")*0.08;
					NombreProducto = 'ION Adquisición Plus';
					break;
				case 3:
					destino = 'LIQUIDITY'; 
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g,"")*0.03;
					NombreProducto='ION Liquidez Plus';
					break;
				case 5:
					destino = 'LAND';
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g,"")*0.08;
					NombreProducto = 'ION Adquisición de Terreno Plus';
					break;
				case 37:
					destino = 'OFFICES_AND_STORES';
					GastosInicialCalculado = this.caso.valorInmueble.replace(/,/g,"")*0.08;
					NombreProducto = 'ION Oficinas y Locales Plus';
					break;
			}
				
			var objGeneraCodigo = {
				person_type: "NATURAL", //valor fijo
				product: destino, //producto
				required_amount: { "amount": this.caso.montoSolicitado.replace(/,/g,""), "currency": "MXN" }, // cantidad requerida
				monthly_income: { "amount": 50000000, "currency": "MXN" },//ingreso mensual valor fijo
				real_estate_value: { "amount": this.caso.valorInmueble.replace(/,/g,""), "currency": "MXN" }, //valor inmobiliario
				term: parseInt(plazo)
			}
				
			this.$axios.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php', objGeneraCodigo, { headers : {'Content-Type':'application/json; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intRespuesta == 0) {
						respuestaData = JSON.parse(response.data.objRespuesta);
						/* if(respuestaData.notification.level=='ERROR'){
							this.$vs.loading.close('#cotizacion > .con-vs-loading')
						}else*/
						
						if (respuestaData.message == 'Internal server error') {
							this.$vs.loading.close('#cotizacion > .con-vs-loading');
							self.$vs.notify({
								title: 'Internal server error ION',
								text: error,
								color: 'danger',
								position:'top-right'
							});
						} else {
							var respuestaIon = JSON.parse(response.data.objRespuesta);
							let itemsIon = {};
							let items = [];
							let cantidadCotiza = items.length;
							itemsIon.Cat = parseFloat(respuestaIon.data.cat);
							itemsIon.Mensualidad = parseFloat(respuestaIon.data.monthly_payment.amount);
							itemsIon.Tasa = parseFloat(respuestaIon.data.interest_rate);
							itemsIon.IngresoMinimo = parseFloat(respuestaIon.data.minimum_income); // parseFloat(respuestaIon.data.monthly_payment.amount)/33
							itemsIon.Enganche = parseFloat(this.caso.valorInmueble.replace(/,/g,"")-this.caso.montoSolicitado.replace(/,/g,""));
							itemsIon.GastosIniciales = parseFloat(respuestaIon.data.initial_expenses);
							itemsIon.Aforo = calculoAforo;
							itemsIon.Plazo = respuestaIon.data.term*12;
							itemsIon.PagoTotal = parseFloat(respuestaIon.data.total_payment);
							itemsIon.MensualidadPromedio = parseFloat(respuestaIon.data.monthly_payment.amount);
							itemsIon.MensualidadInicial = parseFloat(respuestaIon.data.monthly_payment.amount);
							itemsIon.BancoId = 21;
							itemsIon.Banco = 'ION';
							itemsIon.Producto = NombreProducto;
							itemsIon.Esquema = 2;
							itemsIon.Logo = 'LogoIon_130622.png';
							itemsIon.PdfAmortizacion = respuestaIon.data.amortization_table.file;
							itemsIon.JsonAmortizacion = respuestaIon.data.amortization_table.raw_data;
							itemsIon.IdIon= respuestaIon.data.id;
							this.items.push(itemsIon);
							//itemsIon.push(parseInt(this.caso.esquemas[i].value))
						}
					}
					
					this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
					this.$vs.loading.close('#cotizacion > .con-vs-loading');

				}
			).catch(function (error) {
					self.$vs.notify({
						title:'Ocurrio un errros en apiIon',
						text:error,
						color:'danger',
						position:'top-right'
					})
			})
    },
		filtrar(tipo) {

			// console.log( 'tipo', tipo, this.items );

			switch(tipo) {
				case '1':
					this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
					this.filtroName = 'Mensualidad';
					this.filtroId = 1;
					break;
				case '2':
					this.items.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
					this.filtroName = 'Cat';
					this.filtroId = 2;
					break;
				case '3':
					this.items.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
					this.filtroName = 'Tasa';
					this.filtroId = 3;
					break;
				case '4':
          this.items.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
					this.filtroName = 'Pago Total';
					this.filtroId = 4;
					break;
				case '5':
					this.items.sort((a,b) => (a.vctPromociones.length < b.vctPromociones.length) ? 1 : ((b.vctPromociones.length < a.vctPromociones.length) ? -1 : 0));
					this.filtroName = 'Promociones';
					this.filtroId=5;
					break;
				case '6':
					this.items.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
					this.filtroName = 'Ingreso Mínimo';
					this.filtroId = 6;
          break;
        case '7':
					this.items.sort((a,b) => (a.AhorroTotal < b.AhorroTotal) ? 1 : ((b.AhorroTotal < a.AhorroTotal) ? -1 : 0));
					this.filtroName = 'Ahorro Total';
					this.filtroId = 7;
					break;
        case '8':
					this.items.sort((a,b) => (a.MejoraMensualidad < b.MejoraMensualidad) ? 1 : ((b.MejoraMensualidad < a.MejoraMensualidad) ? -1 : 0));
					this.filtroName = 'Mejor Mensualidad';
					this.filtroId = 8;
					break;
      }
		},
		numberFormat(number){
			return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		solicitar(item) {
			// this.dameCaso();
			this.enganche = this.numberFormat(item.Enganche);
			this.bancoSeleccionado = item.Banco;
			this.mensualidadPromedio = this.numberFormat(item.MensualidadPromedio);
			this.tasa = this.numberFormat(item.Tasa);
			this.gastosNotariales = this.numberFormat(item.GastosNotariales);
			this.ingresoMinimo = this.numberFormat(item.IngresoMinimo);
			this.catPromedio = this.numberFormat(item.Cat);
      // $('#modalTabla').modal('show');
			// console.log( 'visualizar: ', item );
      this.generaFormatoProductoElegido(item);
		},
		generaFormatoProductoElegido(item) {
			let self = this;
			let objRequestSolicitaCaso = {
				strApiKey: this.key,
				strMetodo: "GeneraFormatoSimulacion",
				objSolicitud:{
					Id: this.id,
					Banco: item.BancoId,
					Variante: item.Id
        }
      }
			this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(response => {
				if (response.data.intEstatus == 0) {
					this.s = this.id;
					this.o = item.Id;
				} else {
					this.$vs.notify({
						title:'Ocurrio un error en GeneraSolicitud',
						text:response.data.strError,
						color:'danger',
						position:'top-right'
					});
				}
			})
			.catch(function (error) {
				self.$vs.notify({
					title:'Ocurrio un error de sistema',
					text:error,
					color:'danger',
					position:'top-right'
        })
			})
		},
		descargarPDF() {
			let s = this.s;
			let o = this.o;
			location.href= 'https://cotizador.socasesores.com/admin/formatos/SimulaDestino_Inmobiliaria.php?s='+s+'&o='+o+'&broker='+this.brokerSupervisor;
			
			// cierra el modal
			$('#staticBackdrop').modal('hide');
			// remueve el caso de localstorage para crear un nuevo caso la siguiente vez
			localStorage.removeItem('casoInmobiliario');
			localStorage.removeItem('calculadoraRegresa');
			localStorage.removeItem('calculadoraDestino');
			localStorage.removeItem('calculadoraCliente');
			// se redirecciona al modulo "base clientes"
			this.$router.push('/inmobiliaria/1').catch(() => {});
			this.$emit('actualiza_panel', 'Calculadora', 'BaseClientes')
		},
		actualizaOrigen() {
      let self = this;
      let objRequestOrigen = {
				strApiKey: this.key,
				strMetodo: 'ActualizaOrigen',
				objCaso: {
					Id: this.id
        }
      }
			this.$axios.post( '/', objRequestOrigen, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				}
			})
      .then(response => {
				if(response.data.intEstatus == 0) {
					// console.log( 'Actualizando origen caso id: ', this.id );
					// si se actualiza el origen se descarga el pdf
					this.descargarPDF();
				} else {
					this.$vs.notify({
						title:'Ocurrio un error en ActualizaOrigen',
						text:response.data.strError,
						color:'danger',
						position:'top-right'
					});
        }
			})
			.catch(function (error) {
				self.$vs.notify({
					title: 'Ocurrio un error de sistema',
					text: error,
					color: 'danger',
					position: 'top-right'
        })
      })

		},
		regresar() {
			// console.log( 'Regresando...' );
			this.$router.push('/Calculadora').catch(() => {});
			localStorage.setItem('calculadoraRegresa', 'Cliente'); // movimiento regresa
		}
  },
};
</script>

<style lang="scss" scoped>
.bg-calculadora {
	background-color:#F2F2F2;
	border-radius: 10px;
}
.form-check-label {
	margin-top: 0px !important;
}
.tabla-dato-prod{
	color:#006D4E;
	text-align: center;
}
.btn-visualizar {
	color: #006D4E;
	background-color: #F5F5F5;
	border-color: #F5F5F5;
}
.regresar {
	background-color: #fff;
	color: #FF7150;
	border: none;
}
// .alinear-items{
// 	align-items: center;
// }
.btn-regresar-modal {
	border: solid 1px #FF7150;
	color: #FF7150;
}
.marco-contendor {
	border-radius: 10px;
}
.bg-modal-producto {
	background-color: #B4B4B4;
}
.texto-verde-imp {
	color: #006D4E;
}
.aling-bancos {
	align-items: baseline;
}

/********** Nuevos estilos responsivos **********/
.calculadora-inmob-ordenar-item .form-check:after {
	content: '';
	display: block;
	width: 100%;
	float: none;
	clear: both;
}

.modal-calc-inmob-simula .modal-dialog {
	width: 95%;
	max-width: 1140px;
	margin: auto;
}

.modal-calc-inmob-simula-logo {
	max-width: 15.75rem;
	width: 100%;
	height: auto;
}

@media screen and (max-width:896px) {
	.calculadora-inmob-ordenar-item {
		width: auto;
		flex: 1 1 auto;
	}
}

@media screen and (max-width: 576px) {
	.calculadora-inmob-ordenar-item {
		flex: 1 1 50%;
	}
}
</style>